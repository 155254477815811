export const searchFormConfig = {
  formItems: [
    {
      field: 'name',
      type: 'input',
      label: 'general.name'
    },
    {
      field: 'areaPoint',
      type: 'input',
      label: 'geoFence.area-point'
    },
    {
      field: 'creationDate',
      type: 'datepicker',
      label: 'general.creation-date',
      otherOptions: {
        type: 'daterange'
      },
      dateFieldName: ['startDateTime', 'endDateTime']
    }
  ]
}
