export const contentTableConfig = {
  // title: '('param.app-application-list')',
  propList: [
    {
      prop: 'name',
      label: 'general.name'
    },
    {
      prop: 'areaPoint',
      label: 'geoFence.area-point'
    },
    {
      prop: 'description',
      label: 'general.description'
    },
    {
      prop: 'createdTime',
      slotName: 'formatZeroTime',
      label: 'common.app-creation-time'
      // slotName: 'formateTime'
    },
    {
      label: 'common.app-operation',
      width: '300',
      slotName: 'handler'
    }
  ],
  showIndexColumn: true
}
